var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trip-monitoring-contact-sidebar"},[_c('div',{staticClass:"trip-monitoring-contact-sidebar--content"},[(_vm.loading)?[_c('v-layout',{staticStyle:{"background-color":"white","height":"300px"},attrs:{"column":"","justify-space-around":""}},[_c('v-progress-circular',{staticStyle:{"margin":"0 auto"},attrs:{"size":70,"indeterminate":"","color":"primary"}})],1)]:_c('v-form',{ref:"contact-form"},[_c('p',{staticClass:"font-weight-bold font-20"},[_vm._v("Operator Contact Preferences")]),_c('CRInput',{attrs:{"id":"operator-contact-preferences","multi-line":"","auto-grow":"","rules":[
          (v) => _vm.validateLength(v, 255) || 'Must be less than 255 characters',
        ]},model:{value:(_vm.contactPreferenceText),callback:function ($$v) {_vm.contactPreferenceText=$$v},expression:"contactPreferenceText"}}),_c('p',{staticClass:"font-weight-bold font-20"},[_vm._v("Contact Options")]),_c('v-layout',{attrs:{"column":"","align-center":""}},[_c('CRSelect',{staticClass:"w-full",attrs:{"label":"Phone","items":_vm.contactPhoneNumberItems,"hide-details":"","no-data-text":"No available phone numbers"},model:{value:(_vm.selectedPhoneNumber),callback:function ($$v) {_vm.selectedPhoneNumber=$$v},expression:"selectedPhoneNumber"}}),_c('p',{staticClass:"text-primary margin-t-3 cursor-pointer",on:{"click":_vm.copySelectedPhoneNumber}},[_vm._v(" Copy ")])],1),(_vm.mode === 'operator')?_c('v-layout',{attrs:{"column":"","align-center":""}},[_c('CRInput',{staticClass:"w-full",attrs:{"label":"Email","readonly":"true","hide-details":"","no-data-text":"No email available for operator"},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}}),_c('p',{staticClass:"text-primary margin-t-3 cursor-pointer",on:{"click":_vm.copyEmail}},[_vm._v(" Copy ")])],1):_vm._e(),_c('p',{staticClass:"font-weight-bold font-20"},[_vm._v("Add Contact Log")]),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"label":"Contact Time","type":"time"},model:{value:(_vm.contactTime),callback:function ($$v) {_vm.contactTime=$$v},expression:"contactTime"}})],1),_c('v-flex',{staticClass:"margin-l-4",attrs:{"xs6":""}},[_c('CRSelect',{attrs:{"label":"Method","items":_vm.contactMethodMap},model:{value:(_vm.contactMethod),callback:function ($$v) {_vm.contactMethod=$$v},expression:"contactMethod"}})],1)],1),_c('CRInput',{attrs:{"placeholder":"Add new log","multi-line":"","auto-grow":"","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: '*Log note is required',
            error: '*Log note is required',
          }),
        ]},model:{value:(_vm.contactLogText),callback:function ($$v) {_vm.contactLogText=$$v},expression:"contactLogText"}}),_c('p',{staticClass:"font-weight-bold margin-b-0"},[_vm._v("Contact Reminder")]),_c('v-switch',{staticClass:"margin-t-0",attrs:{"id":"contact-set-reminder","label":"Set Reminder","inset":""},model:{value:(_vm.contactSetReminderEnabled),callback:function ($$v) {_vm.contactSetReminderEnabled=$$v},expression:"contactSetReminderEnabled"}}),_c('CRInput',{attrs:{"label":"Time to remind","type":"time","disabled":!_vm.contactSetReminderEnabled},model:{value:(_vm.reminderTime),callback:function ($$v) {_vm.reminderTime=$$v},expression:"reminderTime"}})],1)],2),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('div',[(!_vm.loading)?_c('CRButton',{staticClass:"trip-monitoring-contact-sidebar--action-btn",attrs:{"id":"trip-monitoring-contact-save-btn","loading":_vm.loading || _vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Update Reservation ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }